import { ptBr } from '../i18n/pt-BR';
import { enUS } from '../i18n/en-US';

/**
 * Class to translate
 *
 * @class Translate
 */
export class Translate {

    /**
     * Instance singleton
     *
     * @private
     * @static
     * @type {Translate}
     * @memberof Translate
     */
    private static instance: Translate;

    /**
     * Accept languages
     *
     * @private
     * @type {Array<string>}
     * @memberof Translate
     */
    private readonly acceptLangs: Array<string> = [
        'pt-BR',
        'en-US'
    ];

    /**
     * Translate object
     *
     * @private
     * @type {{ [id: string]: string }}
     * @memberof Translate
     */
    private objTranslate: { [id: string]: string };

    /**
     * Current lang
     *
     * @private
     * @type {string}
     * @memberof Translate
     */
    private currentLang: string = 'pt-BR';

    /**
     * Creates an instance of Translate.
     * @memberof Translate
     */
    private constructor() {
        this.initLanguage();
    }

    /**
     * Get instance singleton
     *
     * @static
     * @returns
     * @memberof Translate
     */
    public static getInstance() {
        if (!Translate.instance) {
            Translate.instance = new Translate();
        }

        return Translate.instance;
    }

    /**
     * Get current language
     *
     * @type {string}
     * @memberof Translate
     */
    public get Lang(): string {
        return this.currentLang;
    }

    /**
     * Set current language
     *
     * @type {void}
     * @memberof Translate
     */
    public set Lang(lang: string) {
        if (lang && this.acceptLangs.indexOf(lang) === -1) {
            throw new Error(`Unsupported language: ${lang}.`);
        }
        else {
            this.currentLang = lang;
            this.initLanguage();
        }

    }

    /**
     * Translation
     *
     * @param {string} key Key of translate
     * @returns {string}
     * @memberof Translate
     */
    public translate(key: string): string {
        return this.objTranslate[key];
    }

    /**
     * Initialize language object
     *
     * @private
     * @memberof Translate
     */
    private initLanguage() {
        switch (this.currentLang) {
            case 'en-US':
                this.objTranslate = enUS;
                break;
            default:
                this.objTranslate = ptBr;
                break;
        }
    }
}